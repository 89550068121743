@charset "utf-8";

// Our variables
$base-font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$background-color:         #ffffff;
$headers-color:            #205493;
$text-color:               #212121;
$reverse-text-color:       #ffffff;
$header-text-color:        #205493;

$link-color:               #0071bc;
$link-visited-color:       #0071bc;
$link-active-color:        #0071bc;
$link-hover-color:         #205493;

$button-color:             #0071bc;
$button-hover-color:       #205493;
$button-text-color:        #fff;

$project-accent-color:     #02bfe7;
$card-heading-color:       #0071bc;

$banner-background-color:  #112e51;
$banner-text-color:        #fff;
$banner-button-color:      #dce4ef;
$banner-button-text-color: #112e51;

$footer-primary-background-color:    #f1f1f1;
$footer-secondary-background-color:  #d6d7d9;
$footer-text-color:        #212121;

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;


// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
// These contain overrides and additions to the Web Design System
// (standards.18f.gov, found locally at assets/css/uswds.<version>.css)
@import
        "base",
        "buttons",
        "navbar",
        "sidenav",
        "home",
        "hero",
        "iframe",
        "pages",
        "posts",
        "projects",
        "banners",
        "footer",
        "toolkit",
        "catalog"
;
