.toolkit-nav {
	background-color: #F3F2F3;
	padding: 1em;
	margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
	.toolkit-nav {
		margin-top: .5em;
	}

}

.wp-caption {
	border: 1px solid #DDD;
	box-shadow: 2px 2px 3px #888;
	/* -webkit-box-shadow: 2px 2px 3px #888; */
	-moz-box-shadow: 2px 2px 3px #888;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	line-height: 18px;
	margin-bottom: 20px;
	padding: 4px;
	padding-top: 22px;
	text-align: center;
	max-width: 96%;
	background: 1px #F9F9F9;
	/* color: #333; */
}

.case-study-list {
	border: 1px solid #DDD;
	box-shadow: 2px 2px 3px #888;
	/* -webkit-box-shadow: 2px 2px 3px #888; */
	-moz-box-shadow: 2px 2px 3px #888;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	line-height: 18px;
	margin-bottom: 20px;
	padding: 4px;
	padding-top: 22px;
	max-width: 96%;
	background: 1px #F9F9F9;
	height: 345px;
	/* color: #333; */
}

td {
	min-width: 175px; 
}

.how-to-grid {
	margin-right: 4em;
	margin-left: 4em;
}

@media screen and (max-width: 600px) {
	.how-to-grid {
		max-width: 200px;
		margin: 0 auto;
		margin-top: 1em;
	}
}


@media screen and (max-width: 600px) {
	.how-to-image {
		margin-top: 2em;
	}
}

.how-to-icon {
	margin-left: 17%;
	margin-bottom:10%
}

@media screen and (max-width: 600px) {
	.how-to-icon {
		display: none;
	}
}

.last-updated {
	padding-bottom: 1em;
}

Section.innovation-communities {
	text-align: center;
}

div.innovation-communities {
	text-align: center;
}

h2.innovation-communities {
	margin: auto;
	padding-bottom: 1em;
	text-align: center;
	}

ul.innovation-communities {
	padding: 0em;
	text-align: center;
	display: inline;
	}

li.innovation-communities {
	padding: .5em;
	display: inline;
	}

img.innovation-communities {
	height: 45px;
	padding-right: 3em;
	padding-left: 3em;
	padding-bottom: 0.5em;
}

img.innovation-communities-code {
	height: 45px;
	padding-right: 3em;
	padding-left: 3em;
	padding-bottom: 1em;
}

img.innovation-communities-challenge {
	height: 65px;
	padding-right: 3em;
	padding-left: 3em;
}

@media screen and (max-width: 600px) {
	img {
		text-align: center;
	}
	img.innovation-communities-challenge {
		padding-left: 0.5em;
		padding-bottom: 5px;
	}
}

.vertical-line {
	display: inline-block;
	border-left: 1px solid #d6d7d9;
	margin: 0 10px;
	height: 75px;
}

@media screen and (max-width: 600px) {
	.vertical-line {
		display: none;
	}
}

p.toolkit-banner {
	font-size: 20px;
}









