.usa-footer-primary-section {
  background-color: $footer-primary-background-color;
}

.usa-footer-secondary_section {
  background-color: $footer-secondary-background-color;
}

footer p,
footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6,
footer a,
footer a:hover,
footer a:visited {
  color: $footer-text-color;
}

.usa-footer-logo p {
  font-size: .7em;
}

.footer-logo {
	max-width: 30rem;
	text-align: center;
	padding-top: 1em;
	padding-left: 1.5em;
}

.usa-footer-logo-img {
  margin-right: 1em;
}

.footer-citsci-logo {
  text-align: right;
}

.usa-link-rss {
  padding-right: 4em;
}

div.footer-questions {
  background-color: #203131; padding: .5em;
}

p.footer-questions {
  text-align: center; color:white;
}

img.footer-questions {
  color: #e87853; height: 12px; padding-left: .5em;
}

@media only screen and (max-width: 600px) {
	.footer-logo {
    max-width: 25rem;
    float: left;
    padding: 0;
	}
}

@media only screen and (max-width: 600px) {
	.usa-footer-logo-img {
    max-width: 6rem !important;
	}
}

@media only screen and (max-width: 1100px) {
	.usa-footer-secondary_section {
    padding-left: 2em !important;
    padding-right: 2em !important;
	}
}

@media only screen and (max-width: 600px) {
	.usa-footer-secondary_section {
    padding-left: 4em !important;
    padding-right: 4em !important;
	}
}

.footer-logo-text {
  font-size: 1.5rem;
  line-height: 1.2;
  //color: #383838;
}

.footer-logo-text-span {
  color: #203131; font-size: 20px !important;
}

@media only screen and (min-width: 600px) {
	.footer-logo-text {
    padding-left: 1em;
	}
}

@media only screen and (max-width: 600px) {
	.footer-socials {
    float: left;
    padding-left: 1em;
  }
}

@media only screen and (min-width: 600px) {
  .mobile-break {
    display: none;
    padding-left: 20px;
  }
}


.feedback-email {
  font-weight: 800 !important;
	max-width: 30rem;
	text-align: center;
  font-size: 1em;
  color: #e87853;
  &:hover {
    color: #FFF;
  }
  &:visited {
    color: #FFF;
  }
}

.footer-accessibility {
  background-color: #d6d7d9;
  padding-left: 240px;
  padding-bottom: 20px;
  margin-top: -20px;
}

.footer-accessibility a {
  font-weight: bold;
}


