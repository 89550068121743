body,
p, ul, ol, li, a  {
  color: $text-color;
}

legend, label,
h1, h2, h3, h4, h5, h6 {
  color: $headers-color;
}

.usa-section-dark {
  legend, label,
  h1, h2, h3, h4, h5, h6,
  p {
    color: $reverse-text-color;
  }
}

.blog-section {
  margin-bottom: 4em;
}

.blog-section ul {
  list-style-type: none;
}

legend,
h1, h2, h3, h4, h5, h6{
  margin-bottom: 20px;
}

h4 {
  margin-bottom: 30px;
}

.usa-full li {
  list-style-type: disc;
}

ul, li, a, h3 {
  margin-bottom: 0; 
  margin-top: 0;
  text-decoration: none;
}

ul, li {
  margin-bottom: .5em;
}

.bullet ul{
  list-style-type: disc;
}

form {
  max-width: 100%;
}

/*
.usa-section p,
.usa-section > ul,
.usa-section > ol,
.usa-section > h1,
.usa-section > h2,
.usa-section > h3,
.usa-section > h4,
.usa-section > h5,
.usa-section > h6 {
  max-width: 62.5rem;
}
*/
.usa-full {
  margin-bottom: 40px;
}

a {
  color: $link-color;
  &:hover {
    color: $link-hover-color;
  }
  &:active {
    color: $link-active-color;
  }
  &:visited {
    color: $link-visited-color;
  }
}

#main:focus {
  outline: none;
}

@media (min-width: 600px) {
  .usa-width-one-whole.usa-section {
    position: relative;
    left: 50%;
    max-width: 62.5rem;
    margin-left: -31.25rem;
  }
}

.text-center {
  text-align: center;
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.5em;
  }
}

.community-about p {
  max-width: 200ch;
}


