 @media screen and (min-width: 600px) {
  .project-container {
    display: flex;
    flex-wrap: wrap;
    width: 102.5%;
  }
}

@media screen and (min-width: 600px) {
  .project-container-toolkit {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
  }
  }

  @media screen and (min-width: 600px) {
  .project-container-toolkit .card-image{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 150px;
    width: 100%;
  }
  }

// Project card ------------- //

// Sets a height on projects so they're the same height and don't break the grid
.card {
  box-shadow: 0 0 0 1px #d6d7d9;
  display: block;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  position: relative;
  text-decoration: none;

  @media all and (min-width: $on-laptop) {
    display: flex;
    flex-direction: column;
    margin-right: 2.3%;
    min-height: 450px;
    width: 31%;
  }

  &:active {
    box-shadow: #3e94cf;
  }
}

.card-link {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}

.card-banner {
  margin: 3rem;
  @media all and (min-width: $on-laptop) {
    flex: 1 0 auto;
  }
}

.card-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 150px;
  width: 100%;
}

.card-image:hover {
    box-shadow: 0 0 0 1px $card-heading-color;
  }

.card-description.card-description {
  color: $card-heading-color;
  margin-top: 0;
}

.card-summary {
  font-size: 16px;
  margin-bottom: 0;

  em {
    font-style: normal;
  }
}

.card-read {
  margin: 0 3rem;
  color: $card-heading-color;
  font-size: $small-font-size;
  font-weight: bold;
  text-decoration: none;

  &:visited {
    color: $card-heading-color;
  }

  svg {
    display: inline-block;
    height: 1.2rem;
    margin-bottom: -1px;
    width: 1.2rem;
    margin-left: 4px;
  }
}

.card-read-dark-background {
  color: #e87853;
  font-size: $small-font-size;
  font-weight: bold;
  text-decoration: none;

  &:visited {
    color: #e87853;
}

  svg {
    display: inline-block;
    height: 1.2rem;
    margin-bottom: -1px;
    width: 1.2rem;
    margin-left: 4px;
  }
}

.card-read-light-background {
  color: #205493;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .project-title {
    font-size: 3rem;
  }
  }