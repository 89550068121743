@media all and (min-width: 951px) {
  .usa-header-basic .usa-navbar {
    // this affects the size of the logo and also the mobile menu nav
    width: 45%;
  }
}

@media all and (max-width: 951px) {
  #logo img {
    // keep header img from overflowing menu bar
    max-height: 3.7rem;
  }
}

.usa-nav-container {
  text-align: left;
}

.usa-nav-primary a:hover {
  color: #FFFFFF;
}

.usa-nav-link:hover span {
  border-bottom-color: $button-color;
}

.usa-nav {
  background: white;
  padding-right: 20px;
}

@media all and (min-width: 951px) {
  .usa-nav {
    padding-top: 2rem;
    float: none;
  }
}
.usa-nav-submenu{
  background-color: #5b616b;
  opacity: 0.90;
}

.usa-nav-primary button[aria-expanded=true] {
  background-color: #5b616b;
  opacity: 0.90;
}

.usa-nav-primary button[aria-expanded=true]:hover {
  background-color: #5b616b;
  opacity: 0.90;
}

.usa-nav-submenu a:visited {
  color: #FFFFFF;
}

.usa-nav-submenu a:hover {
  background-color: #5b616b;
}

.usa-navbar {
  margin-top: 30px;
  margin-left: 20px;
}

.usa-nav-toolkit {
    background: none;
  }

  @media screen and (max-width: 950px) {
    .usa-nav-toolkit {
      background-color: white;
    }
      }

.nav-blog {
  padding-left: 368px;
}

.nav-blog a:hover {
  color: #0071bc;
}


